import React from 'react'
import PropTypes from 'prop-types'
import Layout from 'components/Layout'
import SlicesArea from 'components/SlicesArea'
import { graphql } from 'gatsby'
import { useNavigationLabel } from 'utils/navigation'
import { mergePrismicPreviewData } from 'gatsby-source-prismic'

const IS_BROWSER = typeof window !== 'undefined'

const Page = ({ location, data: staticData, pageContext }) => {
  const previewData = IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__
  const data = mergePrismicPreviewData({ staticData, previewData })

  const page = data.allPrismicPages.edges[0].node.data
  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <Layout
      heroImage={page.hero}
      heroButtonLink={page.hero_button_link}
      heroButtonText={page.hero_button_text}
      crumbs={crumbs}
      crumbLabel={useNavigationLabel(pageContext.uid)}
      metaTitle={page.meta_title}
      metaDescription={page.meta_description}
      pageTitle={page.title}
      location={location}
      hasAwards={page.show_awards}
    >
      <SlicesArea slicesData={page.body} />
      {page.show_reviews && (
        <div className="container pt-10">
          <div
            class="elfsight-app-71ad3e1a-31fa-419a-8a93-b062931f7947"
            data-elfsight-app-lazy
          ></div>
        </div>
      )}
    </Layout>
  )
}

Page.propTypes = {
  location: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query pageByUid($uid: String) {
    allPrismicPages(filter: { uid: { eq: $uid } }) {
      edges {
        node {
          data {
            show_awards
            show_reviews
            body {
              ... on PrismicPagesBodyHeading {
                slice_type
                primary {
                  heading
                  text {
                    text
                  }
                }
              }
              ... on PrismicPagesBodyContent {
                slice_type
                primary {
                  heading
                  rich_text {
                    html
                  }
                  button_colour
                  button_text
                  button {
                    link_type
                    uid
                    url
                  }
                }
              }
              ... on PrismicPagesBodyLargeImage {
                slice_type
                primary {
                  image_width
                  modal_video_id
                  cover_video_direct_link
                  image {
                    localFile {
                      childImageSharp {
                        fluid(quality: 90) {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      }
                    }
                    alt
                  }
                }
              }
              ... on PrismicPagesBodyImageContent {
                slice_type
                primary {
                  button {
                    link_type
                    uid
                    url
                  }
                  button_colour
                  button_text1
                  heading
                  layout
                  image_overlay_text
                  image {
                    localFile {
                      childImageSharp {
                        fluid(quality: 90) {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      }
                    }
                  }
                  rich_text {
                    html
                  }
                }
              }
              ... on PrismicPagesBodyFacilitiesCards {
                slice_type
                id
                items {
                  image {
                    url
                  }
                  name {
                    text
                  }
                  text {
                    text
                  }
                }
              }
              ... on PrismicPagesBodyPathways {
                slice_type
                id
                primary {
                  title: heading {
                    text
                  }
                }
                items {
                  image {
                    url
                  }
                  name {
                    text
                  }
                  text {
                    text
                  }
                  link {
                    url
                    uid
                  }
                }
              }
              ... on PrismicPagesBodyOffers {
                slice_type
                id
                primary {
                  title: heading {
                    text
                  }
                }
                items {
                  image {
                    url
                  }
                  info {
                    html
                  }
                  name {
                    text
                  }
                  text {
                    text
                  }
                }
              }
              ... on PrismicPagesBodyLinkPanel {
                slice_type
                items {
                  panel_title
                  panel_link {
                    url
                    uid
                    link_type
                  }
                  panel_image {
                    localFile {
                      childImageSharp {
                        fluid(quality: 90) {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      }
                    }
                  }
                }
              }
              ... on PrismicPagesBodyTwoImage {
                slice_type
                primary {
                  image_1 {
                    localFile {
                      childImageSharp {
                        fluid(quality: 90) {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      }
                    }
                  }
                  image_2 {
                    localFile {
                      childImageSharp {
                        fluid(quality: 90) {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      }
                    }
                  }
                }
              }
              ... on PrismicPagesBodyLocations {
                slice_type
                items {
                  activity_location {
                    longitude
                    latitude
                  }
                  activity_name
                  activity_description
                  activity_link {
                    url
                    target
                  }
                }
                primary {
                  description
                  title1
                }
              }
              ... on PrismicPagesBodyAppInformation {
                slice_type
                primary {
                  layout
                  heading
                  button_text
                  button {
                    link_type
                    uid
                    url
                  }
                  image {
                    localFile {
                      childImageSharp {
                        fluid(quality: 90) {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      }
                    }
                    alt
                  }
                  rich_text {
                    html
                  }
                }
                items {
                  feature_description
                  feature_icon {
                    localFile {
                      url
                    }
                  }
                }
              }
              ... on PrismicPagesBodyAccordion {
                slice_type
                primary {
                  accordion_heading
                }
                items {
                  accordion_item_title
                  accordion_item_content {
                    html
                  }
                }
              }
              ... on PrismicPagesBodyReviews {
                slice_type
              }
              ... on PrismicPagesBodyGallery {
                slice_type
                id
                items {
                  image {
                    url
                  }
                }
              }
            }
            title
            meta_title
            meta_description
            hero_button_text
            hero {
              localFile {
                childImageSharp {
                  fluid(quality: 90) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
              alt
            }
            hero_button_link {
              uid
              url
              link_type
            }
          }
        }
      }
    }
  }
`

export default Page
